import React, { useEffect, useState } from "react";
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import CheckOutlined from "@ant-design/icons/CheckOutlined"
import CloseOutlined from "@ant-design/icons/CloseOutlined"
import LoadingOutlined from "@ant-design/icons/LoadingOutlined"
import ReloadOutlined from "@ant-design/icons/ReloadOutlined"
import { getAnalyticsListLabel, getOrgDetails, getStages, getSubscriptionDetails, restartCustomDomainStage, restartAuthorizationStage } from "../../lib/api"
import {
  // changeLoaderStatus,
  current_org,
  getActiveBoard,
  getActiveMeetings,
  initAnalyticsLabel,
  tokenExchange,
  update_current_org_creation_stages,
} from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { newOrganisation, Role, SetupStage } from "../../lib/apiRes";
import { useHistory } from "react-router-dom";
import { loginUser } from "../../type/type";
import { orgConfigurationMiddleware } from "../../service/orgConfigurationMiddleware";
const { Text } = Typography;

// Props interface for OrgCreationStatus component
interface OrgCreationStatusProps {
  currentOrg: (orgData: any) => void;
  getActiveBoard(data: any):void;
  getActiveMeetings(data: any):void;
  initCurrentOrg: newOrganisation;
  initAnalyticsLabel: (label: any) => void;
  updateOrgCreationStages: (orgData: any) => void;
  loginUser: loginUser;
  exchangeToken(
    token: any,
    applicant_id: string,
    applicantion_name: string,
    application_desc: string,
    application_imgURL: string,
    applicationRoles: Role[]
  ): void;
}

const OrgCreationStatus: React.FC<OrgCreationStatusProps> = ({
  currentOrg,
  initCurrentOrg,initAnalyticsLabel,
  updateOrgCreationStages,
  loginUser,
  getActiveBoard,
  getActiveMeetings
  // changeInLoaderStatus,
}) => {
  const history = useHistory();

  // State to store setup stages
  const [setupStages, setSetupStages] = useState<SetupStage[] | undefined>();
  const [nextButtonLoading, setNextButtonLoading] = useState<boolean>(false);
  const [startSpin, setStartSpin] = useState<string>("");
  let orgId = localStorage.getItem("orgId");
  // this useEffect will check if there is any workspace available or not
  useEffect(() => {
    localStorage.removeItem('orgCreationStatus');
    // Fetch stages when component mounts
    orgId &&
      getStages(orgId).then((newStages) => {
        updateOrgCreationStages(newStages);
      });
  }, []);
  useEffect(() => {
    setSetupStages(initCurrentOrg.setup_stages);
  }, [initCurrentOrg.setup_stages]);

  const navigateUser = () => {
    if(!orgId)return;
    setNextButtonLoading(true);
    getOrgDetails(orgId)
    .then((appRes) => {
      currentOrg(appRes);
    orgConfigurationMiddleware(initCurrentOrg, loginUser).then((flag) => {
      if (flag) {
          setNextButtonLoading(false)
            history.push(`/o/${orgId}/ats`)
            sessionStorage.removeItem("orgCreated")
            sessionStorage.removeItem("slackCode")
            try {
              getSubscriptionDetails().then((res)=>{
                getActiveBoard(res?.subscriptions?.job_board)
                getActiveMeetings(res?.subscriptions?.meetings_count)
              })
            } catch (error) {
              console.log("Error getting subscription details",error);
            }
            getAnalyticsListLabel()
              .then(analyticsLabels => {
                initAnalyticsLabel(analyticsLabels)
                setNextButtonLoading(false)
              })
              .catch(error => {
                setNextButtonLoading(false)
                console.error("Error fetching analytics labels:", error)
              })
          }
          setNextButtonLoading(false)
    });
  }).catch((error)=>{
    console.log(error)
    setNextButtonLoading(false)
  })
  };

  const handleOnClick = async (stageName: string) => {
    const req = {
      id: initCurrentOrg.id,
      user_id: loginUser.user.id,
    };
    try {
      setStartSpin(stageName);
      let updatedStages: SetupStage[] = [];
      if (stageName === "Configuring authorization") {
        updatedStages = await restartAuthorizationStage(req);
      } else if (stageName === "Integrating default domain") {
        updatedStages = await restartCustomDomainStage(req);
      }
      setSetupStages((prevStages) =>
        prevStages?.map((stage) =>
          stage.name === stageName
            ? updatedStages.find(
                (updatedStage) => updatedStage.name === stageName
              ) || stage
            : stage
        )
      );
      setStartSpin("");
    } catch (error) {
      console.error("Error during stage update", error);
      setStartSpin("");
       }
  };

  return (
    <div
      className="w-full h-full overflow-y-scroll rounded-md bg-white" >
      <div className="w-full h-full flex justify-start items-start">
        <Card
          bordered={false}
          className=" w-full h-full p-6 create-org-card"
          style={{ boxShadow: "none" }}
        >
          <div className="space-y-6 flex flex-col h-full justify-between">
            <div className="space-y-2 flex flex-col">
            <Text className="text-lg font-semibold">
                Organization setup
              </Text>
              <Text className="text-gray-500">
                Almost Ready! We&apos;re setting up your hiring workspace. This
                will just take a few moments.
              </Text>
            </div>
            <div
              className="border flex flex-col my-2 gap-2 px-6 py-4 rounded-lg"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {setupStages?.map((currentStage) => (
                <div
                  key={currentStage.name}
                  className={`
                    group relative p-4 rounded-lg border transition-all duration-200
                    ${
                      currentStage.stage === "doing"
                        ? "bg-blue-50 border-blue-200"
                        : currentStage.stage === "done"
                        ? "bg-green-50 border-green-200"
                        : currentStage.stage === "failed"
                        ? "bg-red-50 border-red-200"
                        : "bg-white border-gray-200"
                    }
                    ${currentStage.stage === "doing" ? "shadow-sm" : ""}
                  `}
                >
                  <div className="flex items-center space-x-4">
                    {/* Status Icon */}
                    <div className="flex-shrink-0">
                      {currentStage.stage === "failed" ? (
                        <CloseOutlined style={{ color: "#ff0000" }} />
                      ) : currentStage.stage === "doing" ? (
                        <LoadingOutlined style={{ color: "#4B48A5" }} />
                      ) : currentStage.stage === "done" ? (
                        <CheckOutlined style={{ color: "#008000" }} />
                      ) : (
                        <LoadingOutlined style={{ color: "#4B48A5" }} />
                      )}
                    </div>

                    {/* Stage Name */}
                    <div className="flex-grow w-full">
                      <div
                        className={` flex w-full justify-between
                       
                        ${
                          currentStage.stage === "doing"
                            ? "text-blue-700"
                            : currentStage.stage === "done"
                            ? "text-green-700"
                            : currentStage.stage === "failed"
                            ? "text-red-700"
                            : "text-gray-700"
                        }
                      `}
                      >
                        {currentStage.name}
                        {currentStage.stage === "failed" ? (
                          <div className="">
                            {" "}
                            <ReloadOutlined
                              style={{ color: "#4B48A5" }}
                              spin={startSpin === currentStage.name}
                              onClick={() => handleOnClick(currentStage.name)}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between  items-center ">
              <div>
                Need help? Contact our&nbsp;
                <a
                  href="https://support.tacitbase.com/"
                  style={{ color: "#009D79" }}
                >
                  support.
                </a>
              </div>
              <Button
                type="primary"
                className="w-max text-right"
                onClick={() => navigateUser()}
                size="large"
                loading={nextButtonLoading}
                disabled={
                  !setupStages?.every(
                    (currentsStage) => currentsStage.stage === "done"
                  )
                }
              >
                Next
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

// Map state to props
const mapStateToProps = (state: any) => ({
  initCurrentOrg: state.opening.currentOrg,
  loginUser: state.opening.loginUser,
});

// Map dispatch to props
const mapDispatchToProps = (dispatch: Dispatch) => ({
  // changeInLoaderStatus: (payload: {
  //   loader: boolean;
  //   message: string;
  //   skeleton: boolean;
  // }) => dispatch(changeLoaderStatus(payload)),
  initAnalyticsLabel: (label: any) => dispatch(initAnalyticsLabel(label)),
  getActiveBoard:(data: any)=> dispatch(getActiveBoard(data)),
  getActiveMeetings:(data: any)=> dispatch(getActiveMeetings(data)),
  updateOrgCreationStages: (orgData: any) =>
    dispatch(update_current_org_creation_stages(orgData)),
  currentOrg: (orgData: any) => dispatch(current_org(orgData)),
  exchangeToken: (
    token: any,
    applicant_id: string,
    applicantion_name: string,
    application_desc: string,
    application_imgURL: string,
    applicationRoles: Role[]
  ) =>
    dispatch(
      tokenExchange(
        token,
        applicant_id,
        applicantion_name,
        application_desc,
        application_imgURL,
        applicationRoles
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgCreationStatus);
